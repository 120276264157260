import React, { useState } from "react";
import { useStyle, colorStatus } from "./style";
import { useHistory, useParams } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";

import OrderServices from "../../../services/orderServices";
import { useMemo } from "react";

import { formatDateUSA } from "../../../utils/formatDate";
import { Modal } from "../../../components/Modals/Modal";

import { toast } from "react-toastify";

import { useEffect } from "react";
import { StatusIcon } from "../../../components/Icons/StatusIcon";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  breakTypes,
  orderTypes,
  canceledTypes,
} from "../../../types/orderTypes";
import {
  BreakResolve,
  ConfirmModalBody,
  CouponModalBody,
  DetailCoupon,
  DetailRefund,
  DetailReposition,
  DetailWithoutSolution,
  DetailCreditedonWallet,
  DiscountOnTicket,
  InvoiceImage,
  NoSolutionModal,
  OrderCanceledModal,
  OtherNegotiationsModalBody,
  Reschedule,
  RescheduleRetry,
  PaymentEdit,
} from "./modalBody";
import { HistoryTable, OrderTable, OrderTableFooter } from "./tables";
import {
  DeliveryBodyCard,
  DeliveryButtonsCard,
  DeliveryFooterCard,
  DeliveryStatusCard,
  DeliveryTitleCard,
  OrderBodyCard,
  OrderButtonsCard,
  OrderFooterCard,
  OrderStatusCard,
  OrderTitleCard,
} from "./card";
import { ContainerPage } from "../../../components/ContainerPage";
import { WalletModalBody } from "../../../components/Modals/WalletModal";
import PageLoader from "../../../components/PageLoader";
import formatCurrency from "../../../utils/formatCurrency";
import { Button, ButtonLink } from "../../../components/Button";
import { AlertModal } from "../../../components/AlertModal";
import { LoadingBackDrop } from "../../../components/LoadingBackDrop";
import { BackIconButton } from "../../../components/StyledIconButton";
import { useRef } from "react";
import { useAuth } from "../../../hooks/useAuth";
import TicketService from "../../../services/ticketService";

const OrderDetail = () => {
  const { orderID } = useParams();
  const classes = useStyle();
  const history = useHistory();
  const todayDate = new Date();
  const { permissions } = useAuth();

  const [orderData, setOrderData] = useState(orderTypes);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date();
    const itsSunday = (day) => !day;
    const isSundayMondayOrSaturday = (day) => [0, 1, 6].includes(day);
    const allowedTime = isSundayMondayOrSaturday(todayDate.getDay())
      ? new Date().getHours() >= 18
      : new Date().getHours() >= 19;
    const addDays = allowedTime ? 2 : 1;
    date.setDate(date.getDate() + addDays);
    if (itsSunday(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  });
  const [selectedPaymentDueDate, setSelectedPaymentDueDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const isSunday = (day) => [0].includes(day);
    const isSaturday = (day) => [6].includes(day);
    if (isSunday(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    if (isSaturday(date.getDay())) {
      date.setDate(date.getDate() + 2);
    }
    return date;
  });
  const [selectedTimeFrom, setSelectedTimeFrom] = useState(7);
  const [selectedTimeTo, setSelectedTimeTo] = useState(10);
  const [showCalendar, setShowCalendar] = useState(false);

  const [breakIndex, setBreakIndex] = useState("");
  const [modalBody, setModalBody] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [complaintValue, setComplaintValue] = useState(0);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [payment, setPayment] = useState({});
  const [isBoleto, setIsBoleto] = useState(false);
  const [openOtherNegotiations, setOpenOtherNegotiations] = useState(false);
  const [breakInfo, setBreakInfo] = useState(breakTypes);
  const [iFoodOptions, setIfoodOptions] = useState({
    value: "",
    trackingCode: "",
  });
  const [confirmText, setConfirmText] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [alertText, setAlertText] = useState("");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [selectRescheduleReason, setSelectRescheduleReason] = useState(0);
  const [rescheduleObservation, setRescheduleObservation] = useState(null);
  const [editPaymentObservation, setEditPaymentObservation] = useState(null);
  const [rescheduleReasonList, setRescheduleReasonList] = useState([]);
  const orderStatus = orderData?.status;
  const processingReschedule =
    orderData?.reschedule_ticket?.status === "aberto";
  const isCanceledOrRefused =
    orderStatus === "cancelado" ||
    orderStatus === "recusado" ||
    processingReschedule;
  const [openOrderCanceled, setOpenOrderCanceled] = useState(false);
  const [cancelReasonList, setCancelRefusedList] = useState([]);
  const [selectedCancelRefused, setSelectedCancelRefused] = useState(0);
  const [canceledObservation, setCanceledObservation] = useState("");
  const [openWithOutSolution, setOpenWithOutSolution] = useState(false);
  const withOutSolutionObservation = useRef(null);
  const [copyText, setCopyText] = useState("");
  const [alertLink, setAlertLink] = useState("");
  const [textLink, setTextLink] = useState("");
  const obligatoryCancelReason = [7, 10, 13];
  const [rescheduleErrorInfo, setRescheduleErrorInfo] = useState(null);

  const [canceledInfo, setCanceledInfo] = useState(canceledTypes);

  const getOrderDetail = async () => {
    try {
      setLoading(true);
      const { data } = await OrderServices.GetOrderDetail(orderID);
      const { data: dataRescheduleReason } =
        await OrderServices.getReasonReschedule();
      const { data: dataCancelRefused } =
        await OrderServices.getReasonOrderCanceled();
      setOrderData((prev) => ({ ...prev, ...data }));
      setPayment(
        data?.payment && data.payment.length > 0
          ? data.payment[data.payment.length - 1]
          : undefined
      );
      setIsBoleto(
        Array.isArray(data?.payment) &&
          data.payment.some(
            (payment) => payment?.method_payment_name === "boleto"
          )
      );
      setRescheduleReasonList(dataRescheduleReason);
      setCancelRefusedList(dataCancelRefused);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, [orderID]);

  const reschedule = async (date, timeFrom, timeTo) => {
    try {
      handleShowBackDrop();
      timeFrom = Number(timeFrom) < 10 ? `0${timeFrom}:00` : `${timeFrom}:00`;
      timeTo = Number(timeTo) < 10 ? `0${timeTo}:00` : `${timeTo}:00`;

      const data = {
        subjectId: 14,
        orderId: orderID,
        clientId: orderData?.client_id,
        externalCode: `${orderID}#reschedule`,
        referenceObj: {
          order: {
            client: orderData.client,
            sub_total_value: orderData.sub_total_value,
            delivery_value: orderData.delivery_value,
            status: orderData.status,
            id: orderID,
          },
        },
        actionJson: {
          reschedule: {
            scheduleDate: date,
            scheduleTimeFrom: timeFrom,
            scheduleTimeTo: timeTo,
            reason: selectRescheduleReason,
            reasonName: rescheduleReasonList.find(
              (reason) => reason.id === selectRescheduleReason
            )?.name,
            comments: rescheduleObservation,
          },
        },
      };

      if (rescheduleErrorInfo) {
        data["actionJson"]["reschedule"]["productsToRemove"] =
          rescheduleErrorInfo.products;
      }

      await TicketService.createTicket(data);
      toast.info("Processando reagendamento...");
      setSelectRescheduleReason(0);
      setRescheduleObservation("");
      setRescheduleErrorInfo(null);
      await getOrderDetail();
    } catch (error) {
      if (error?.response?.data?.info?.products && itemsFormatted.length > 1) {
        setRescheduleErrorInfo(error?.response?.data?.info);
        setModalBody("rescheduleRetry");
        setOpenModal(true);
      } else {
        let message = "Erro ao reagendar";
        console.log(error.response);
        if (error?.response?.data?.info?.products) {
          message = "Estoque mínimo insuficiente para reagendar o pedido";
        }

        if (
          typeof error?.response?.data === "string" &&
          error?.response?.data?.includes("defaulting")
        ) {
          message = "Cliente Inadimplente";
        }
        toast.error(message);
        setSelectRescheduleReason(0);
        setRescheduleErrorInfo(null);
        setRescheduleObservation("");
        await getOrderDetail();
      }
    } finally {
      handleHideBackDrop();
    }
  };

  const subTotal = useMemo(() => {
    const subTotalCalc = orderData?.item.reduce((subTotal, item) => {
      const priceAdjustment = orderData?.is_dinamic_price
        ? item?.price_adjustment
        : 0;
      const price =
        item.total_quantity * item.unit_price +
        (priceAdjustment > 0 ? priceAdjustment : 0);
      return subTotal + price;
    }, 0);
    return subTotalCalc;
  }, [orderData?.item]);

  const items = useMemo(() => {
    return orderData?.item || {};
  }, [orderData.item]);

  const complaints = useMemo(() => {
    return orderData?.complaints || [];
  }, [orderData.complaints]);

  const complaintsTotalValue = () => {
    return complaints?.reduce((acc, complaint) => {
      return complaint.items?.reduce((accItems, { complaint_value }) => {
        return accItems + complaint_value;
      }, acc);
    }, 0);
  };

  const cancelReasonName = (idCancelRefused) => {
    const { name } =
      cancelReasonList.find(({ id }) => id === idCancelRefused) || "";
    return name;
  };

  const handleChangeRescheduleReason = (e) => {
    const reasonId = e.target.value;
    setSelectRescheduleReason(reasonId);
  };

  const handleRescheduleObservation = (e) => {
    const comments = e.target.value;
    setRescheduleObservation(comments);
  };

  const handleOpenReschedule = () => {
    setModalBody("reschedule");
    setOpenModal(true);
  };

  const handleOpenPaymentEdit = () => {
    setModalBody("payment");
    setOpenModal(true);
  };

  const handleEditPaymentObservation = (e) => {
    const comments = e.target.value;
    setEditPaymentObservation(comments);
  };

  const handleOpenImage = () => {
    if (orderData?.delivered_data?.photo) {
      setModalBody("invoiceImage");
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalBody("");
    setSelectRescheduleReason(0);
    setRescheduleObservation("");
    setEditPaymentObservation(null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleCloseNegotiation = () => {
    setOpenCouponModal(false);
    setOpenWalletModal(false);
    setOpenOtherNegotiations(false);
    setOpenModal(true);
  };

  const handleDateChange = (date) => {
    const isSunday = (day) => !day;
    const isSundayMondayOrSaturday = (day) => [0, 1, 6].includes(day);
    const allowedTime = isSundayMondayOrSaturday(todayDate.getDay())
      ? new Date().getHours() >= 18
      : new Date().getHours() >= 19;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const todayMoreTwoDays = new Date();
    todayMoreTwoDays.setDate(today.getDate() + 2);

    if (isSunday(date.getDay())) {
      return toast.error("Não realizamos entregas aos domingos!");
    }
    if (allowedTime && tomorrow.getDate() === date.getDate()) {
      return toast.error("Escolha um prazo maior de entrega!");
    }

    const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];

    if (orderData.delivery_info) {
      const allowedDays = orderData.delivery_info?.allowed_days.map(
        (allowedDay) => new Date(allowedDay).toISOString().split("T")[0]
      );

      if (!allowedDays.includes(isoDate)) {
        return toast.error(
          "Nossa janela de entrega não está aberta para a área desse cliente nesse dia!"
        );
      }
    } else if (isSunday(todayMoreTwoDays.getDay()) || allowedTime) {
      if (isSunday(todayMoreTwoDays.getDay()) && allowedTime) {
        if (date.getDate() > today.getDate() + 4) {
          return toast.error(
            "A loja não permite agendamento com essa distância!"
          );
        }
      } else if (date.getDate() > today.getDate() + 3) {
        return toast.error(
          "A loja não permite agendamento com essa distância!"
        );
      }
    } else {
      if (date.getDate() > today.getDate() + 2) {
        return toast.error(
          "A loja não permite agendamento com essa distância!"
        );
      }
    }

    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handleDateChangeEditPayment = (date) => {
    const isSundayOrSaturday = (day) => [0, 6].includes(day);

    if (isSundayOrSaturday(date.getDay())) {
      return toast.error("Escolha um dia útil!");
    }

    setSelectedPaymentDueDate(date);
    setShowCalendar(false);
  };

  const handleChangeTimeFrom = (e) => {
    setSelectedTimeFrom(e.target.value);
  };

  const handleChangeTimeTo = (e) => {
    setSelectedTimeTo(e.target.value);
  };

  const handleScheduledSave = () => {
    const invalidDate = handleDateChange(selectedDate);

    const paymentMethodIsTino =
      Array.isArray(orderData?.payment) &&
      orderData.payment.some(
        (payment) => payment?.method_payment_name === "tino"
      );

    if (paymentMethodIsTino) {
      return toast.error("Não é possível reagendar pedidos Tino.");
    }

    if (invalidDate) {
      return toast.error(
        "Nossa janela de entrega não está aberta para a área desse cliente nesse dia!"
      );
    }
    if (!selectRescheduleReason) {
      return toast.error("Selecione o motivo do reagendamento");
    }
    if (!rescheduleObservation) {
      return toast.error("Escreva um comentário sobre o reagendamento");
    }

    setOrderData((prev) => ({
      ...prev,
      scheduled_date: selectedDate,
    }));
    setOrderData((prev) => ({
      ...prev,
      scheduled_time_from: selectedTimeFrom,
    }));
    setOrderData((prev) => ({ ...prev, scheduled_time_to: selectedTimeTo }));
    setOpenModal(false);
    reschedule(formatDateUSA(selectedDate), selectedTimeFrom, selectedTimeTo);
  };

  const editDueDatePayment = async (date) => {
    try {
      handleShowBackDrop();
      const data = {
        subjectId: 16,
        orderId: orderID,
        clientId: orderData?.client_id,
        externalCode: `${orderID}#dueDatePayment`,
        actionJson: {
          paymentEdit: {
            dueDate: date,
            observation: editPaymentObservation,
          },
        },
      };

      await TicketService.createTicket(data);
      toast.success("Alteração de vencimento registrada!");
      setEditPaymentObservation(null);
      await getOrderDetail();
    } catch (error) {
      let message = `Erro ao alterar vencimento!`;
      toast.error(message);
      setEditPaymentObservation(null);
      await getOrderDetail();
    } finally {
      handleHideBackDrop();
    }
  };

  const handlePaymentSave = () => {
    const invalidDate = handleDateChangeEditPayment(selectedPaymentDueDate);

    if (invalidDate) {
      return toast.error("Só é possível colocar o vencimento para um dia útil");
    }
    if (!editPaymentObservation) {
      return toast.error("Escreva um comentário sobre a alteração");
    }
    editDueDatePayment(formatDateUSA(selectedPaymentDueDate));
    setOpenModal(false);
  };

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleShowDetail = (id, modalBody) => {
    modalBody = modalBody.replace(/\s/g, "");
    setBreakIndex(id);
    setModalBody(modalBody);
    setOpenModal(true);
  };

  const handleShowBackDrop = () => {
    setShowBackDrop(true);
  };

  const handleHideBackDrop = () => {
    setShowBackDrop(false);
  };

  const reducerBreakerQty = (productSku) => {
    const { total_quantity } = items.find((item) => item.sku === productSku);

    return orderData?.complaints?.reduce((acc, { items }) => {
      acc = items.reduce((accItem, { origin_sku, quantity_complaint }) => {
        if (origin_sku === productSku) {
          return accItem - quantity_complaint;
        }
        return accItem;
      }, acc);
      return acc;
    }, total_quantity);
  };

  const itemsFormatted = useMemo(() => {
    const feeInPercent = (orderData?.fee_in_percent ?? 0) / 100;

    return items.map((item) => {
      const unitPrice = item?.unit_price - feeInPercent * item?.unit_price;
      const priceAdjustment = orderData?.is_dinamic_price
        ? item?.price_adjustment
        : 0;
      const availableQuantity = reducerBreakerQty(item.sku) || 0;
      const totalItensValue =
        item?.total_quantity * unitPrice + priceAdjustment ?? 0;
      const newTotalItensValue =
        availableQuantity * unitPrice + priceAdjustment ?? 0;
      const portionType = item?.portion_type === "peso" ? "Kg" : "Un";
      const receivedQuantity = orderData?.is_dinamic_price
        ? item?.received_quantity ?? item?.total_quantity
        : item?.total_quantity;
      return {
        id: item?.id,
        sku: item?.sku,
        productName: item?.description,
        orderId: item?.order,
        portionName: item?.portion_name,
        productId: item?.product_id,
        totalItemValue: totalItensValue,
        portionType: portionType,
        quantity: item?.quantity,
        availableQuantity: parseFloat(availableQuantity.toFixed(3)),
        totalQuantity: item?.total_quantity,
        receivedQuantity: Number.isInteger(receivedQuantity)
          ? receivedQuantity
          : parseFloat(receivedQuantity.toFixed(3)),
        priceAdjustment: priceAdjustment,
        totalItensValue: formatCurrency(totalItensValue),
        newTotalItensValue:
          totalItensValue !== newTotalItensValue
            ? formatCurrency(newTotalItensValue)
            : null,
      };
    });
  }, [items]);

  const generateCouponCode = () => {
    const code = `${
      orderData.client.name.slice(0, 4) ||
      orderData.client.trade_name.slice(0, 3)
    }${new Date().getTime()}`;

    setCouponCode(code.replace(/\s/g, "").toUpperCase());
  };

  const handleConfirmText = () => {
    const { negotiation } = breakInfo;
    if (negotiation === "1") {
      if (isCanceledOrRefused) {
        setConfirmText(`Pedido ${orderStatus}, quer gerar cupom mesmo assim?`);
      } else {
        setConfirmText("Tem certeza que deseja gerar um cupom?");
      }
    }
    if (negotiation === "2") {
      if (isCanceledOrRefused) {
        setConfirmText(
          `Pedido ${orderStatus}, quer gerar um reembolso mesmo assim?`
        );
      } else {
        setConfirmText("Tem certeza que deseja gerar um reembolso?");
      }
    }
    if (negotiation === "3") {
      if (isCanceledOrRefused) {
        setConfirmText(
          `Pedido ${orderStatus}, quer gerar uma reposição mesmo assim?`
        );
      } else {
        setConfirmText("Tem certeza que deseja gerar uma reposição?");
      }
    }
    if (negotiation === "5") {
      if (isCanceledOrRefused) {
        setConfirmText(
          `Pedido ${orderStatus}, quer gerar um desconto no boleto mesmo assim?`
        );
      } else {
        setConfirmText("Tem certeza que deseja gerar um desconto no boleto?");
      }
    }
    if (negotiation === "7") {
      if (isCanceledOrRefused) {
        setConfirmText(
          `Pedido ${orderStatus}, quer creditar na carteira mesmo assim?`
        );
      } else {
        setConfirmText("Tem certeza que deseja gerar crédito na carteira?");
      }
    }
  };

  const handleOpenCoupon = (value) => {
    generateCouponCode();
    setComplaintValue(value);
    setBreakInfo((prev) => ({ ...prev, negotiation: "1" }));
    setOpenCouponModal(true);
    setOpenModal(false);
  };

  const handleOpenCreditAnnotation = (value) => {
    setComplaintValue(value);
    setBreakInfo((prev) => ({ ...prev, negotiation: "7" }));
    setOpenWalletModal(true);
    setOpenModal(false);
  };

  const handleOpenOtherNegotiations = (value) => {
    setComplaintValue(value);
    setOpenOtherNegotiations(true);
    setIfoodOptions({ value: "", trackingCode: "" });
    setBreakInfo(breakTypes);
    setOpenModal(false);
  };

  const handleConfirm = (confirmType) => {
    const refundType = canceledInfo?.refund?.type;
    if (confirmType === "negotiation") {
      const { negotiation, refundOption, redeliveryOption } = breakInfo;

      handleConfirmText();
      setConfirmAction(confirmType);

      if (!negotiation) {
        return toast.error("Escolha uma tratativa");
      }

      if (negotiation === "2") {
        if (refundOption === "transferBank") {
          if (
            !breakInfo.bankAccount ||
            !breakInfo.bankBranch ||
            !breakInfo.bankDocument ||
            !breakInfo.bankName ||
            !breakInfo.typeAccount
          ) {
            return toast.error("Preencha todos os dados bancários");
          }
        } else if (refundOption === "pix") {
          if (!breakInfo.typePix) {
            return toast.error("Informe o tipo da chave do pix");
          }
          if (!breakInfo.keyPix) {
            return toast.error("Informe a chave do pix");
          }
          if (!breakInfo.favored) {
            return toast.error("Informe o favorecido");
          }
        } else {
          return toast.error("Escolha o tipo de reembolso");
        }
      }

      if (negotiation === "3") {
        if (
          complaints.some(
            (complaint) => complaint?.action?.name === "Reposição"
          )
        ) {
          return toast.error("Já existe uma reposição para esse pedido");
        }
        if (redeliveryOption === "ifood") {
          if (!iFoodOptions.value || !iFoodOptions.trackingCode) {
            return toast.error("Preencha os dados do iFood");
          }
        } else if (redeliveryOption === "nextDay") {
          if (selectedTimeFrom + 3 > selectedTimeTo) {
            return toast.error(
              "Deve haver um intervalo de 3 horas entre a entrega inicial e a entrega final"
            );
          }
        } else if (redeliveryOption === "") {
          return toast.error("Escolha uma forma de entrega");
        }
      }
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteCoupon") {
      setConfirmText("Tem certeza que deseja excluir o cupom?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteWalletCredit") {
      setConfirmText("Tem certeza que deseja remover o crédito?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteBreak") {
      setConfirmText("Tem certeza que deseja excluir essa quebra?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }
    if (confirmType === "orderCanceled") {
      if (complaints.length > 0) {
        return toast.error("Não é possível cancelar um pedido com quebra!");
      }
      if (!selectedCancelRefused) {
        return toast.error("Selecione um motivo");
      }
      if (
        obligatoryCancelReason.includes(selectedCancelRefused) &&
        !canceledObservation
      ) {
        return toast.error("Escreva um comentário");
      }
      if (orderData?.client?.type === "PF") {
        if (
          refundType === "pix" &&
          (!canceledInfo?.refund?.keyPix || !canceledInfo?.refund?.favored)
        ) {
          return toast.error("Preencha todos os campos!");
        }
      }
      setConfirmText("Tem certeza que deseja cancelar o pedido?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteReposition") {
      const { redeliveryOption } =
        complaints[breakIndex]?.action_json?.reposition;

      if (redeliveryOption === "nextDay") {
        const { scheduleDate } =
          complaints[breakIndex]?.action_json?.reposition;
        if (
          new Date(scheduleDate).setHours(0, 0, 0, 0) <
          new Date(todayDate.setHours(0, 0, 0, 0))
        ) {
          return toast.error(
            "Não é possível excluir a reposição com data de entrega menor do que a de hoje"
          );
        }
      }
      if (redeliveryOption === "ifood") {
        return toast.error(
          "Não é possível excluir a reposição feita por iFood"
        );
      }
      setConfirmText("Tem certeza que deseja excluir a reposição?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteRefund") {
      setConfirmText("Tem certeza que deseja excluir o reembolso?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "withoutSolution") {
      if (!withOutSolutionObservation.current.value) {
        return toast.error("Escreva um comentário");
      }
      setConfirmText(
        "Tem certeza que deseja finalizar a quebra sem tratativa?"
      );
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "confirmPayment") {
      setConfirmText(
        "Deseja confirmar?\nSe o pagamento não compensar no banco até amanha, voltará a ficar pendente"
      );
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteWithoutSolution") {
      setConfirmText("Tem certeza que deseja excluir essa tratativa?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "deleteTicketDiscount") {
      setConfirmText("Tem certeza que deseja excluir essa tratativa?");
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }
  };

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    setOpenAlert(false);
    setOpenConfirmModal(false);
    setOpenCouponModal(false);
    setOpenWalletModal(false);
    setOpenOtherNegotiations(false);
    setOpenWithOutSolution(false);
    setOpenModal(false);
    handleCloseOrderCanceled();
    getOrderDetail();
    setCopyText("");
    setAlertLink("");
  };

  const handleSubmit = async () => {
    setOpenConfirmModal(false);
    const getTicketId = (ticketData) => {
      try {
        const { results } = ticketData;
        const { id: ticketId } = results[0];
        return ticketId;
      } catch {
        throw new Error("Ticket da tratativa não localizado");
      }
    };
    if (confirmAction === "negotiation") {
      const { negotiation } = breakInfo;
      const complaintId = complaints[breakIndex]?.complaint_id;
      let data = {};
      if (negotiation === "1") {
        data = {
          subjectId: 3,
          externalCode: `${complaintId}#complaint`,
          orderId: orderID,
          clientId: orderData?.client_id,
          referenceObj: {
            order: {
              client: orderData?.client,
              payment: orderData?.payment,
              status: orderData?.status,
              sub_total_value: orderData?.sub_total_value,
              delivery_value: orderData?.delivery_value,
              store_name: orderData?.store_name,
              catalog_id: orderData?.catalog_id,
              id: orderID,
            },
            complaint: complaints[breakIndex],
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                coupon: {
                  key: couponCode,
                  useType: "oneUse",
                  type: "absolute",
                  limitQuantity: 1,
                  discountValue: complaintValue,
                  startValidPeriod: new Date(),
                  finalValidPeriod: new Date(
                    todayDate.setDate(todayDate.getDate() + 30)
                  ),
                  clientName:
                    orderData.client.trade_name || orderData.client.name,
                },
              },
            },
          },
        };
      }

      if (negotiation === "2") {
        if (breakInfo.refundOption === "transferBank") {
          data = {
            subjectId: 4,
            externalCode: `${complaintId}#complaint`,
            orderId: orderID,
            clientId: orderData?.client_id,
            referenceObj: {
              order: {
                client: orderData?.client,
                payment: orderData?.payment,
                status: orderData?.status,
                sub_total_value: orderData?.sub_total_value,
                delivery_value: orderData?.delivery_value,
                store_name: orderData?.store_name,
                catalog_id: orderData?.catalog_id,
                id: orderID,
              },
              complaint: complaints[breakIndex],
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  refund: {
                    refundOption: breakInfo?.refundOption,
                    bankAccount: breakInfo?.bankAccount,
                    bankBranch: breakInfo?.bankBranch,
                    bankDocument: breakInfo?.bankDocument,
                    bankName: breakInfo?.bankName,
                    typeAccount: breakInfo?.typeAccount,
                    refundValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo?.refundOption === "pix") {
          data = {
            subjectId: 4,
            externalCode: `${complaintId}#complaint`,
            orderId: orderID,
            clientId: orderData?.client_id,
            referenceObj: {
              order: {
                client: orderData?.client,
                payment: orderData?.payment,
                status: orderData?.status,
                sub_total_value: orderData?.sub_total_value,
                delivery_value: orderData?.delivery_value,
                store_name: orderData?.store_name,
                catalog_id: orderData?.catalog_id,
                id: orderID,
              },
              complaint: complaints[breakIndex],
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  refund: {
                    refundOption: breakInfo?.refundOption,
                    typePix: breakInfo?.typePix,
                    keyPix: breakInfo?.keyPix,
                    refundValue: complaintValue,
                    favored: breakInfo.favored,
                  },
                },
              },
            },
          };
        }
      }

      if (negotiation === "3") {
        if (breakInfo.redeliveryOption === "nextDelivery") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId: orderID,
            clientId: orderData?.client_id,
            referenceObj: {
              order: {
                client: orderData?.client,
                payment: orderData?.payment,
                status: orderData?.status,
                sub_total_value: orderData?.sub_total_value,
                delivery_value: orderData?.delivery_value,
                store_name: orderData?.store_name,
                catalog_id: orderData?.catalog_id,
                id: orderID,
              },
              complaint: complaints[breakIndex],
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo.redeliveryOption === "nextDay") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId: orderID,
            clientId: orderData?.client_id,
            referenceObj: {
              order: {
                client: orderData?.client,
                payment: orderData?.payment,
                status: orderData?.status,
                sub_total_value: orderData?.sub_total_value,
                delivery_value: orderData?.delivery_value,
                store_name: orderData?.store_name,
                catalog_id: orderData?.catalog_id,
                id: orderID,
              },
              complaint: complaints[breakIndex],
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    scheduleDate: new Date(selectedDate),
                    scheduleTimeFrom: `${selectedTimeFrom}:00`,
                    scheduleTimeTo: `${selectedTimeTo}:00`,
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo.redeliveryOption === "ifood") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId: orderID,
            clientId: orderData?.client_id,
            referenceObj: {
              order: {
                client: orderData?.client,
                payment: orderData?.payment,
                status: orderData?.status,
                sub_total_value: orderData?.sub_total_value,
                delivery_value: orderData?.delivery_value,
                store_name: orderData?.store_name,
                catalog_id: orderData?.catalog_id,
                id: orderID,
              },
              complaint: complaints[breakIndex],
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    iFoodValue: iFoodOptions.value || 0,
                    iFoodTrackingCode: iFoodOptions.trackingCode || "",
                    date: new Date(),
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }
      }

      if (negotiation === "5") {
        data = {
          subjectId: 5,
          externalCode: `${complaintId}#complaint`,
          orderId: orderID,
          clientId: orderData?.client_id,
          referenceObj: {
            order: {
              client: orderData?.client,
              payment: orderData?.payment,
              status: orderData?.status,
              sub_total_value: orderData?.sub_total_value,
              delivery_value: orderData?.delivery_value,
              store_name: orderData?.store_name,
              catalog_id: orderData?.catalog_id,
              id: orderID,
            },
            complaint: complaints[breakIndex],
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                discountOnTicket: {
                  orderID,
                  discountValue: Number(complaintValue.toFixed(2)),
                },
              },
            },
          },
        };
      }

      if (negotiation === "7") {
        data = {
          subjectId: 13,
          externalCode: `${complaintId}#complaint`,
          orderId: orderID,
          clientId: orderData?.client_id,
          referenceObj: {
            order: {
              client: orderData?.client,
              payment: orderData?.payment,
              status: orderData?.status,
              sub_total_value: orderData?.sub_total_value,
              delivery_value: orderData?.delivery_value,
              store_name: orderData?.store_name,
              catalog_id: orderData?.catalog_id,
              id: orderID,
            },
            complaint: complaints[breakIndex],
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                wallet: {
                  creditValue: complaintValue,
                  observation: "",
                },
              },
            },
          },
        };
      }

      try {
        handleShowBackDrop();

        setAlertText(
          `Tratativa para o pedido #${orderID} foi salva com sucesso`
        );

        await TicketService.createTicket(data);

        showAlert();
        handleClose();
      } catch (error) {
        const errorMessage = error?.response?.data?.msg;
        toast.error(`Erro ao criar trativa: ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }
    if (confirmAction === "deleteCoupon") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "fechado",
          subjectId: 3,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Cupom excluído com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;
        toast.error(`Erro ao excluir cupom ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "deleteWalletCredit") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "fechado",
          subjectId: 13,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Crédito removido com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;
        toast.error(`Erro ao remover crédito ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "deleteBreak") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "aberto",
          subjectId: 1,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Quebra excluída com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;
        toast.error(`Erro ao excluir quebra ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "orderCanceled") {
      try {
        handleShowBackDrop();
        const data = {
          subjectId: 2,
          externalCode: `${orderID}#cancel`,
          orderId: orderID,
          type: "Cancelamento de pedido",
          clientId: orderData?.client_id,
          referenceObj: {
            order: {
              client: orderData.client,
              payment: orderData.payment,
              sub_total_value: orderData.sub_total_value,
              delivery_value: orderData.delivery_value,
              status: orderData.status,
              store_name: orderData?.store_name,
              catalog_id: orderData?.catalog_id,
              id: orderID,
            },
          },
          actionJson: {
            canceledInfo: {
              status: "cancelado",
              reason: {
                id: selectedCancelRefused,
                name: cancelReasonName(selectedCancelRefused),
              },
              observation: canceledObservation,
              refund: { ...canceledInfo.refund, value: orderData.total_value },
              clientType: orderData.client?.type,
            },
          },
        };

        await TicketService.createTicket(data);

        setCopyText("Pronto! O pedido foi cancelado");
        setTextLink("Comprovante de estorno");
        setAlertText("Pedido cancelado com sucesso!");
        showAlert();
      } catch {
        toast.error("Erro ao cancelar pedido");
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "confirmPayment") {
      try {
        handleShowBackDrop();
        const data = {
          subjectId: 15,
          externalCode: `${orderID}#confirmPayment`,
          orderId: orderID,
          type: "Confirmação de pagamento",
          clientId: orderData?.client_id,
        };

        await TicketService.createTicket(data);

        setAlertText("Pagamento do pedido confirmado com sucesso!");
        showAlert();
      } catch (error) {
        toast.error(String(error));
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "deleteReposition") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "fechado",
          subjectId: 8,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Reposição excluída com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;
        toast.error(`Erro ao excluir reposição ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "deleteRefund") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "aberto",
          subjectId: 4,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Reembolso excluído com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;
        toast.error(`Erro ao excluir reembolso ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "withoutSolution") {
      const complaintId = complaints[breakIndex]?.complaint_id;
      const data = {
        subjectId: 7,
        externalCode: `${complaintId}#complaint`,
        orderId: orderID,
        clientId: orderData?.client_id,
        referenceObj: {
          order: {
            client: orderData?.client,
            payment: orderData?.payment,
            status: orderData?.status,
            sub_total_value: orderData?.sub_total_value,
            delivery_value: orderData?.delivery_value,
            store_name: orderData?.store_name,
            catalog_id: orderData?.catalog_id,
            id: orderID,
          },
          complaint: complaints[breakIndex],
        },
        actionJson: {
          solution: {
            action: 4,
            action_json: {
              without_solution: {
                complaintValue: complaintValue,
                observation: withOutSolutionObservation.current.value,
              },
            },
          },
        },
      };

      try {
        handleShowBackDrop();
        await TicketService.createTicket(data);
        setAlertText(
          `Tratativa para o pedido #${orderID} foi salva com sucesso`
        );
        showAlert();
        handleClose();
      } catch {
        toast.error("Erro ao criar tratativa");
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "deleteWithoutSolution") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "fechado",
          subjectId: 7,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Tratativa excluída com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;
        toast.error(`Erro ao excluir tratativa ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }

    if (confirmAction === "deleteTicketDiscount") {
      try {
        handleShowBackDrop();
        const { data: ticketData } = await TicketService.getTickets({
          externalCode: `${complaints[breakIndex]?.complaint_id}#complaint`,
          status: "fechado",
          subjectId: 5,
        });

        const ticketId = getTicketId(ticketData);

        await TicketService.deleteTicket({ ticketId });
        setAlertText(`Tratativa excluída com sucesso`);
        showAlert();
        handleClose();
      } catch (err) {
        const errorMessage = err?.response?.data?.msg || err;

        toast.error(`Erro ao excluir tratativa ${errorMessage}`);
      } finally {
        handleHideBackDrop();
      }
    }
  };

  const handleBreakInfoChange = (field, value) => {
    setBreakInfo((prev) => ({ ...prev, [field]: value }));
  };

  const handleIfoodOptionsChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setIfoodOptions({ ...iFoodOptions, [field]: value });
  };

  const handleOpenOrderCanceled = () => {
    setCanceledInfo(canceledTypes);
    setOpenOrderCanceled(true);
  };

  const handleCloseOrderCanceled = () => {
    setCanceledObservation("");
    setSelectedCancelRefused(0);
    setOpenOrderCanceled(false);
  };

  const handleChangeCancelRefused = (e) => {
    const value = e.target.value;
    setSelectedCancelRefused(value);
  };

  const handleChangeCancelObservation = (e) => {
    const value = e.target.value;
    setCanceledObservation(value);
  };

  const handleOpenWithoutSolution = (value) => {
    withOutSolutionObservation.current = null;
    setComplaintValue(value);
    setOpenWithOutSolution(true);
    setOpenModal(false);
  };

  const handleCloseWithoutSolution = () => {
    setOpenModal(true);
    setOpenWithOutSolution(false);
  };

  const handleChangeCancelInfo = ({ field, value }) => {
    if (field === "type") {
      setCanceledInfo({
        refund: { [field]: value, value: orderData.total_value },
      });
    } else {
      setCanceledInfo({
        ...canceledInfo,
        refund: {
          ...canceledInfo["refund"],
          [field]: value,
          value: orderData.total_value,
        },
      });
    }
  };

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  };

  const renderModalBody = () => {
    const body = {
      Cupom: (
        <DetailCoupon
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
      invoiceImage: (
        <InvoiceImage
          handleClose={handleClose}
          orderID={orderID}
          orderData={orderData}
        />
      ),
      reschedule: (
        <Reschedule
          handleClose={handleClose}
          handleChangeTimeFrom={handleChangeTimeFrom}
          handleChangeTimeTo={handleChangeTimeTo}
          handleDateChange={handleDateChange}
          handleScheduledSave={handleScheduledSave}
          handleShowCalendar={handleShowCalendar}
          allowedDates={orderData.delivery_info?.allowed_days?.map(
            (date) => new Date(date)
          )}
          orderID={orderID}
          selectedDate={selectedDate}
          selectedTimeFrom={selectedTimeFrom}
          selectedTimeTo={selectedTimeTo}
          showCalendar={showCalendar}
          selectRescheduleReason={selectRescheduleReason}
          handleChangeRescheduleReason={handleChangeRescheduleReason}
          rescheduleObservation={rescheduleObservation}
          handleRescheduleObservation={handleRescheduleObservation}
          rescheduleReasonList={rescheduleReasonList}
        />
      ),
      payment: (
        <PaymentEdit
          handleClose={handleClose}
          handleDateChange={handleDateChangeEditPayment}
          handlePaymentEditSave={handlePaymentSave}
          handleShowCalendar={handleShowCalendar}
          orderID={orderID}
          selectedDate={selectedPaymentDueDate}
          showCalendar={showCalendar}
          editObservation={editPaymentObservation}
          handleEditObservation={handleEditPaymentObservation}
          handleConfirmPayment={() => handleConfirm("confirmPayment")}
        />
      ),
      rescheduleRetry: (
        <RescheduleRetry
          handleClose={handleClose}
          handleScheduledSave={handleScheduledSave}
          orderID={orderID}
          errorInfo={rescheduleErrorInfo}
          orderItems={itemsFormatted}
          orderTotalValue={orderData.total_value}
          setRescheduleErrorInfo={setRescheduleErrorInfo}
        />
      ),
      Descontonoboleto: (
        <DiscountOnTicket
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
      Reembolso: (
        <DetailRefund
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
      Reposição: (
        <DetailReposition
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
      resolve: (
        <BreakResolve
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          storeName={orderData?.store_name}
          handleOpenCoupon={handleOpenCoupon}
          handleOpenCreditAnnotation={handleOpenCreditAnnotation}
          handleOpenOtherNegotiations={handleOpenOtherNegotiations}
          handleOpenWithoutSolution={handleOpenWithoutSolution}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
      Semtratativa: (
        <DetailWithoutSolution
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
      Creditadonacarteira: (
        <DetailCreditedonWallet
          orderStatus={orderStatus}
          handleClose={handleClose}
          complaint={complaints[breakIndex]}
          handleConfirm={handleConfirm}
          hasPermission={hasPermission}
        />
      ),
    };
    return body[modalBody] || <Box></Box>;
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <ContainerPage>
      <Grid className={classes.backButton}>
        <BackIconButton onClick={() => history.goBack(-1)} />
        Voltar
      </Grid>

      <Grid className={classes.titleDetail}>
        <h1>{`Consulta de pedido > Pedido # ${orderID}`}</h1>
      </Grid>

      {orderData?.reschedule_ticket?.actionJson?.cancellation?.info
        ?.product && (
        <Grid className={classes.cardContainer}>
          <Grid className={classes.rescheduleCard}>
            <div>
              <ErrorOutlineIcon
                style={{ width: "1.8em", height: "auto", color: "#E11414" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <p>{`Falha ao reagendar pedido`}</p>
                <span
                  style={{
                    margin: 0,
                    marginLeft: 18,
                    fontSize: "14px",
                    color: "#757575",
                  }}
                >
                  {`${orderData?.reschedule_ticket?.actionJson?.cancellation?.info?.product?.name} está fora de estoque`}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      )}

      {orderData?.reschedule_ticket?.actionJson?.cancellation?.reason?.includes(
        "black-listed"
      ) && (
        <Grid className={classes.cardContainer}>
          <Grid className={classes.rescheduleCard}>
            <div>
              <ErrorOutlineIcon
                style={{ width: "1.8em", height: "auto", color: "#E11414" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <p>{`Falha ao reagendar pedido`}</p>
                <span
                  style={{
                    margin: 0,
                    marginLeft: 18,
                    fontSize: "14px",
                    color: "#757575",
                  }}
                >
                  {orderData?.reschedule_ticket?.actionJson?.cancellation
                    ?.reason === "black-listed-area"
                    ? "Área de entrega na Blacklist"
                    : `Cliente na Blacklist`}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      )}

      {orderData?.reschedule_ticket?.actionJson &&
        orderData?.reschedule_ticket?.status === "fechado" && (
          <Grid className={classes.cardContainer}>
            <Grid className={classes.rescheduleCard}>
              <div>
                <StatusIcon statusId={"7"} />
                <p>{`Pedido reagendado por ${orderData?.reschedule_ticket?.updatedBy?.name} - #${orderData?.reschedule_ticket?.actionJson?.reschedule?.newReplacementOrder}`}</p>
              </div>
              <ButtonLink
                to={`../${orderData?.reschedule_ticket?.actionJson?.reschedule?.newReplacementOrder}/detail`}
                loading={true}
              >
                Ver novo pedido
              </ButtonLink>
            </Grid>
          </Grid>
        )}

      {orderData?.reschedule_ticket?.actionJson &&
        orderData?.reschedule_ticket?.status === "aberto" && (
          <Grid className={classes.cardContainer}>
            <Grid className={classes.rescheduleCard}>
              <div>
                <ErrorOutlineIcon
                  style={{ width: "1.8em", height: "auto", color: "#58ACFA" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <p>{`Processando reagendamento...`}</p>
                  <span
                    style={{
                      margin: 0,
                      marginLeft: 18,
                      fontSize: "14px",
                      color: "#757575",
                    }}
                  >
                    {`Solicitado por ${orderData?.reschedule_ticket?.updatedBy?.name}`}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        )}

      {orderData?.cancellation_ticket?.actionJson && (
        <Grid className={classes.cardContainer}>
          <Grid className={classes.refuseCard}>
            <div className="major">
              <StatusIcon statusId={"8"} />
              <div>
                <p>{`Pedido cancelado por ${orderData?.cancellation_ticket?.updatedBy?.name}`}</p>
                <div>
                  <span>{`Motivo: ${orderData?.cancellation_ticket?.actionJson?.canceledInfo?.reason?.name}`}</span>
                  {orderData?.cancellation_ticket?.actionJson?.canceledInfo
                    ?.observation && (
                    <span>{`Comentário: ${orderData?.cancellation_ticket?.actionJson?.canceledInfo?.observation}`}</span>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}

      <Grid className={classes.cardContainer}>
        <Grid className={classes.card}>
          <OrderTitleCard
            handleOpenReschedule={handleOpenReschedule}
            handleOpenPayment={handleOpenPaymentEdit}
            status={orderData?.status}
            hasPermission={hasPermission}
            processingReschedule={processingReschedule}
            isBoleto={isBoleto}
            isPaymentDisabled={
              isCanceledOrRefused || orderData?.kind === "replacement"
            }
          />

          <OrderStatusCard colorStatus={colorStatus} orderData={orderData} />

          <OrderBodyCard orderData={orderData} />

          <OrderFooterCard orderData={orderData} />

          <OrderButtonsCard orderData={orderData} />
        </Grid>

        <Grid className={classes.card}>
          <DeliveryTitleCard />

          <DeliveryStatusCard colorStatus={colorStatus} orderData={orderData} />

          <DeliveryBodyCard orderData={orderData} />

          <DeliveryFooterCard
            orderData={orderData}
            handleOpenImage={handleOpenImage}
          />

          <DeliveryButtonsCard orderID={orderID} orderData={orderData} />
        </Grid>
      </Grid>

      {complaints.length > 0 && (
        <Grid className={classes.orderTableContainer}>
          <Box className={classes.historyTitle}>
            <h4>Histórico</h4>
          </Box>
          <Grid className={classes.historyTableContent}>
            <HistoryTable
              complaints={complaints}
              handleShowDetail={handleShowDetail}
              orderData={orderData}
            />
          </Grid>
        </Grid>
      )}

      <Grid className={classes.orderTableContainer}>
        <Box className={classes.orderTableHead}>
          <h4>{`Pedido #${orderID}`}</h4>
          <Box
            className="action"
            style={{
              visibility: hasPermission("can see actions")
                ? "visible"
                : "hidden",
            }}
          >
            {hasPermission("can refuse order") && (
              <Button
                btn="secondary"
                onClick={handleOpenOrderCanceled}
                disabled={
                  isCanceledOrRefused || orderData?.kind === "replacement"
                }
              >
                Cancelar pedido
              </Button>
            )}
            {hasPermission("can report complaint") && (
              <ButtonLink
                to={`../${orderID}/break/create`}
                loading={true}
                disabled={isCanceledOrRefused}
              >
                Reportar quebra
              </ButtonLink>
            )}
          </Box>
        </Box>
        <Grid className={classes.orderTableContent}>
          <OrderTable items={itemsFormatted} />
        </Grid>
        <OrderTableFooter
          orderData={orderData}
          subTotal={subTotal}
          complaintsTotalValue={complaintsTotalValue()}
        />
      </Grid>

      <Modal open={openModal} onClose={handleClose}>
        {renderModalBody()}
      </Modal>

      <Modal open={openCouponModal} onClose={handleCloseNegotiation}>
        <CouponModalBody
          orderData={orderData}
          couponCode={couponCode}
          complaintValue={formatCurrency(complaintValue)}
          handleClose={handleCloseNegotiation}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <Modal open={openWalletModal} onClose={handleCloseNegotiation}>
        <WalletModalBody
          data={orderData}
          complaintValue={formatCurrency(complaintValue)}
          handleClose={handleCloseNegotiation}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <Modal open={openConfirmModal}>
        <ConfirmModalBody
          handleClose={handleCloseConfirm}
          handleSubmit={handleSubmit}
          confirmText={confirmText}
        />
      </Modal>

      <Modal open={openOtherNegotiations} onClose={handleCloseNegotiation}>
        <OtherNegotiationsModalBody
          handleClose={handleCloseNegotiation}
          orderData={orderData}
          payment={payment}
          breakInfo={breakInfo}
          handleBreakInfoChange={handleBreakInfoChange}
          orderID={orderID}
          complaintValue={complaintValue}
          handleConfirm={handleConfirm}
          handleChangeTimeFrom={handleChangeTimeFrom}
          handleChangeTimeTo={handleChangeTimeTo}
          handleDateChange={handleDateChange}
          handleShowCalendar={handleShowCalendar}
          selectedDate={selectedDate}
          selectedTimeFrom={selectedTimeFrom}
          selectedTimeTo={selectedTimeTo}
          showCalendar={showCalendar}
          iFoodOptions={iFoodOptions}
          handleIfoodOptionsChange={handleIfoodOptionsChange}
        />
      </Modal>

      <OrderCanceledModal
        isOpen={openOrderCanceled}
        handleClose={handleCloseOrderCanceled}
        selectedCancelRefused={selectedCancelRefused}
        handleChangeCancelRefused={handleChangeCancelRefused}
        canceledObservation={canceledObservation}
        handleChangeCancelObservation={handleChangeCancelObservation}
        cancelReasonList={cancelReasonList}
        handleConfirm={handleConfirm}
        payment={payment}
        orderData={orderData}
        obligatoryCancelReason={obligatoryCancelReason}
        handleChangeCancelInfo={handleChangeCancelInfo}
      />

      <NoSolutionModal
        close={handleCloseWithoutSolution}
        open={openWithOutSolution}
        withOutSolutionObservation={withOutSolutionObservation}
        handleConfirm={handleConfirm}
      />

      <AlertModal
        handleClose={closeAlert}
        open={openAlert}
        text={alertText}
        link={alertLink}
        textLink={textLink}
        copyText={copyText}
      />
      <LoadingBackDrop open={showBackDrop} />
    </ContainerPage>
  );
};

export default OrderDetail;
