import { Grid } from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { formatCpfCnpj } from "../../../utils/formatCpfCnpj";
import { formatDate } from "../../../utils/formatDate";
import formatCurrency from "../../../utils/formatCurrency";
import { FaRegFileAlt } from "react-icons/fa";
import { VscRemove } from "react-icons/vsc";
import { RiFilePaper2Line } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { useStyle } from "./style";
import {
  EditIconButton,
  PaymentIconButton,
} from "../../../components/StyledIconButton";

export const OrderTitleCard = ({
  handleOpenReschedule,
  handleOpenPayment,
  status,
  hasPermission,
  processingReschedule,
  isBoleto,
  isPaymentDisabled,
}) => {
  const isPermitted = hasPermission("can reschedule order");
  const classes = useStyle();
  return (
    <Grid className={classes.titleCard}>
      <p>Informações do Pedido</p>
      {!["cancelado", "recusado", "reagendado"].includes(status) &&
        isPermitted &&
        !processingReschedule && (
          <div>
            {isBoleto && !isPaymentDisabled && (
              <PaymentIconButton onClick={handleOpenPayment} />
            )}
            <EditIconButton onClick={handleOpenReschedule} />
          </div>
        )}
    </Grid>
  );
};

export const OrderStatusCard = ({ colorStatus, orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderStatusCard}>
      <p>
        <Brightness1Icon style={{ color: colorStatus(orderData.status) }} />
      </p>
      <p>{orderData.status}</p>
    </Grid>
  );
};

export const OrderBodyCard = ({ orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderBodyCard}>
      <Grid className="bodyInfo">
        <span className="infoItem">
          <span className="title">Cliente</span>
          <span className="content">
            {orderData?.client?.trade_name || orderData?.client?.name}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">CPF/CNPJ</span>
          <span className="content">
            {formatCpfCnpj(orderData?.client?.cpf_cnpj)}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Responsável</span>
          <span className="content">
            {orderData?.client?.responsible?.username}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Loja</span>
          <span className="content">
            {orderData?.store_name || "Não informado"}
          </span>
        </span>

        {orderData?.kind === "replacement" && (
          <span className="infoItem">
            <span className="title">Reposição</span>
            <span className="content">
              Pedido {`#${orderData?.reference_order}` || "Não informado"}
            </span>
          </span>
        )}

        {orderData?.kind === "reschedule" && (
          <span className="infoItem">
            <span className="title">Reagendamento</span>
            <span className="content">
              Pedido {`#${orderData?.reference_order}` || "Não informado"}
            </span>
          </span>
        )}
      </Grid>
      <Grid className="bodyInfo">
        <span className="infoItem">
          <span className="title">Data do Pedido</span>
          <span className="content">{formatDate(orderData.order_date)}</span>
        </span>

        <span className="infoItem">
          <span className="title">Data agendada de entrega</span>
          <span className="content">
            {formatDate(orderData.scheduled_date)}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Horário Agendado</span>
          <span className="content">
            {`
                    ${
                      orderData?.scheduled_time_from?.length > 2
                        ? orderData?.scheduled_time_from?.slice(0, 2)
                        : orderData?.scheduled_time_from
                    } hs
                    às ${
                      orderData?.scheduled_time_to?.length > 2
                        ? orderData?.scheduled_time_to?.slice(0, 2)
                        : orderData?.scheduled_time_to
                    } hs
                  `}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Valor do Pedido</span>
          <span className="content">
            {formatCurrency(orderData?.total_adjusted_value)}
          </span>
        </span>
      </Grid>
    </Grid>
  );
};

export const OrderFooterCard = ({ orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderFooterCard}>
      <span className="title">Endereço</span>
      <span className="content">
        {`${
          orderData?.billing_address?.street
            ? `Rua ${orderData?.billing_address?.street}`
            : ""
        } ${orderData?.billing_address?.number || ""} ${
          orderData?.billing_address?.complement
            ? ` - ${orderData?.billing_address?.complement}`
            : ""
        }`}
      </span>
      <span className="content">
        {`${orderData?.billing_address?.neighborhood || ""} - ${
          orderData?.billing_address?.city || ""
        }, ${orderData?.billing_address?.state || ""} `}
      </span>
    </Grid>
  );
};

export const OrderButtonsCard = ({ orderData }) => {
  const classes = useStyle();

  return (
    <Grid className={classes.orderButtonsCard}>
      {orderData?.urls?.bill_url && (
        <>
          <span>
            <FaRegFileAlt />
            {orderData?.urls?.bill_url ? (
              <a
                href={orderData?.urls?.bill_url}
                target="_blank"
                rel="noreferrer"
              >
                Acessar boleto
              </a>
            ) : (
              <span className="noLink">Acessar boleto</span>
            )}
          </span>
          <span>
            <VscRemove />
          </span>
        </>
      )}

      <span>
        <RiFilePaper2Line />
        {orderData?.urls?.invoice_url ? (
          <a
            href={orderData?.urls?.invoice_url}
            target="_blank"
            rel="noreferrer"
          >
            Acessar Nota Fiscal
          </a>
        ) : (
          <span className="noLink">Acessar Nota Fiscal</span>
        )}
      </span>
    </Grid>
  );
};

export const DeliveryTitleCard = () => {
  const classes = useStyle();
  return (
    <Grid className={classes.titleCard}>
      <p>Informações da Entrega</p>
    </Grid>
  );
};

export const DeliveryStatusCard = ({ orderData, colorStatus }) => {
  const classes = useStyle();
  const description = orderData?.delivered_data?.description;
  return (
    <Grid className={classes.orderStatusCard}>
      {description && (
        <>
          <p>
            <Brightness1Icon
              style={{
                color: description.includes("Concluído")
                  ? colorStatus("aprovado")
                  : colorStatus("recusado"),
              }}
            />
          </p>
          <p>
            {description.includes("Concluído")
              ? "Entrega Concluída"
              : description}
          </p>
        </>
      )}
    </Grid>
  );
};

export const DeliveryBodyCard = ({ orderData }) => {
  const classes = useStyle();
  return (
    <>
      <Grid className={classes.orderBodyCard}>
        <Grid className="bodyInfo">
          <span className="infoItem">
            <span className="title">Nome do Recebedor</span>
            <span className="content">
              {orderData?.delivered_data?.receiver || "Não informado"}
            </span>
          </span>

          <span className="infoItem">
            <span className="title">RG/CPF</span>
            <span className="content">
              {orderData?.delivered_data?.receiverDocument || "Não informado"}
            </span>
          </span>

          <span className="infoItem">
            <span className="title">Nº da Rota</span>
            <span className="content">
              {orderData?.delivered_data?.route || "Não informado"}
            </span>
          </span>
        </Grid>
        <Grid className="bodyInfo">
          <span className="infoItem">
            <span className="title">Data da Entrega</span>
            <span className="content">
              {orderData?.delivered_data?.deliveryDate
                ? formatDate(orderData?.delivered_data?.deliveryDate)
                : "Não informado"}
            </span>
          </span>

          <span className="infoItem">
            <span className="title">Horário da tentativa/entrega</span>
            <span className="content">
              {orderData?.delivered_data?.attemptTime
                ? orderData?.delivered_data?.attemptTime
                : "Não informado"}
            </span>
          </span>

          <span className="infoItem">
            <span className="title">Descrição do recebedor</span>
            <span className="content">
              {orderData?.delivered_data?.receiverDescription
                ? orderData?.delivered_data?.receiverDescription
                : "Não informado"}
            </span>
          </span>
        </Grid>
      </Grid>
      <Grid className={classes.orderBodyCard} style={{ marginTop: 0 }}>
        <Grid className="bodyInfo">
          <span className="infoItem">
            <span className="title">Observação do Motorista</span>
            <span className="content">
              {orderData?.delivered_data?.observation || "Não informado"}
            </span>
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export const DeliveryFooterCard = ({ handleOpenImage, orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderFooterCard}>
      <span className="title">Comprovante</span>
      <span className="contentImg" onClick={handleOpenImage}>
        {orderData?.delivered_data?.photo && (
          <img src={orderData?.delivered_data?.photo} alt="Comprovante" />
        )}
      </span>
    </Grid>
  );
};

export const DeliveryButtonsCard = ({ orderData, orderID }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.deliveryButtonsCard}>
      <span>
        {orderData?.delivered_data?.photo && (
          <>
            <IoMdDownload />
            <a
              href={orderData?.delivered_data?.photo}
              download={`Comprovante pedido ${orderID}`}
              rel="noreferrer"
            >
              Baixar
            </a>
          </>
        )}
      </span>
    </Grid>
  );
};
